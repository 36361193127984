import styled from 'styled-components';
import { breakpoints, colors, fonts } from '../../style-utilities/variables';

export default styled.div`
  margin: 0.75em auto;
  margin-top: 0;
  max-width: 1500px;
  .intro-heading {
    text-align: center;
    margin: 2rem 0.5rem 0;
    padding: 0 0 1rem 0;
    p {
      font-weight: 400;
      font-size: 16px;
      color: ${colors.primaryGray};
    }
    h2 {
      text-align: center;
      @media (min-width: ${breakpoints.tablet}) {
        font-size: 28px;
      }
    }
    @media (min-width: ${breakpoints.desktop}) {
      padding: 0 0 3rem 0;
    }
    @media (max-width: ${breakpoints.mobileMax}) {
      text-align: left;
    }
  }
  .navigation {
    text-align: center;
    padding: 1rem 0 0 0;
    li {
      display: inline-block;
      padding: 0 1rem;
    }
    a {
      color: ${colors.black};
      text-decoration: underline;
      &:hover {
        font-weight: bold;
      }
    }
  }
  /* ************************** */
  /* FEATURED CASE STUDIES GRID */
  /* ************************** */
  /* caseStudiesGrid is the class I pass down as props */
  .caseStudiesGrid {
    background-color: ${colors.secondaryGray};
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    #gridTitle {
      margin: 1.5rem auto;
      @media (min-width: ${breakpoints.desktop}) {
        margin: 2rem auto;
      }
    }
    img {
      width: 150%;
      margin-bottom: 0;
    }
    .grid {
      /* THEEEE GRID: */
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      justify-items: center;
      grid-template-areas:
        'item1'
        'item2'
        'item3'
        'item4'
        'quotation';
      grid-row-gap: 1rem;
      @media (min-width: ${breakpoints.tablet}) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, 1fr) auto;
        grid-template-areas:
          'item1 quotation'
          'item1 quotation'
          'item3 item2'
          'item3 item4';
        grid-row-gap: 2rem;
        grid-column-gap: 2rem;
      }
      @media (min-width: ${breakpoints.desktop}) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;

        grid-row-gap: 2rem;
        grid-column-gap: 2rem;
      }
      .grid-item-client {
        margin-top: 0;
        margin-bottom: 10px;
      }
      .grid-item-title {
        display: none;
      }
      .grid-item-excerpt,
      a {
        font-size: 13px;
      }
      .grid-item-excerpt {
        margin-bottom: 1em;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
      }
      /* top left corner */
      #grid-item-0 {
        grid-area: item1;
        @media (min-width: ${breakpoints.desktop}) {
          grid-column: 1 / span 2;
          grid-row: 1 / span 1;

          .grid-item-image {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            min-height: 0;
            height: 100%;
          }
        }
      }
      /* imageless. first row middle */
      #grid-item-1 {
        grid-area: item2;
        background-color: ${colors.secondary};
        text-align: center;
        @media (min-width: ${breakpoints.desktop}) {
          grid-column: 3 / span 1;
          grid-row: 1 / span 1;
        }
        .img {
          display: none;
        }
      }
      /* right-hand side one column and two rows: */
      #grid-item-2 {
        grid-area: item3;
        @media (min-width: ${breakpoints.desktop}) {
          grid-column: 4 / span 1;
          grid-row: 1 / span 2;
        }
      }
      /* imageless. second row left */
      #grid-item-3 {
        grid-area: item4;
        background-color: ${colors.secondary};
        text-align: center;
        @media (min-width: ${breakpoints.desktop}) {
          grid-column: 1 / span 1;
          grid-row: 2 / span 1;
        }
        .img {
          display: none;
        }
      }
      /* imageless. first row middle */
      #grid-item-quotation {
        grid-area: quotation;
        @media (min-width: ${breakpoints.desktop}) {
          grid-column: 2 / span 2;
          grid-row: 2 / span 1;
        }
      }
    }
    .grid-items {
      background: ${colors.white};

      a {
        color: ${colors.black};
        text-decoration: underline;
      }
      .clickableOnly {
        text-decoration: none;
      }
    }
    .grid-items.noImage {
      color: ${colors.white};
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 1rem;

      .grid-item-excerpt {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
    }
    .grid-items.noImage a {
      color: ${colors.white};
    }
  }
  .withImage {
    .grid-item-text {
      padding: 1rem;
      p {
        margin-bottom: 0;
      }
    }

    &:nth-child(even) {
      .grid-item-image {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        overflow: hidden;
        @media (min-width: ${breakpoints.desktop}) {
          height: 100%;
        }
      }
      .grid-item-text {
        padding: 1rem;
        width: 100%;
      }
      .grid-item-client {
        color: ${colors.white};
      }

      .grid-item-excerpt,
      a {
        color: ${colors.white};
      }
    }
  }

  #grid-item-quotation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem;
    svg > path {
      fill: ${colors.primaryGray};
    }
    .testament {
      margin-top: 1em;
      font-size: 1rem;
      font-weight: 600;
      text-align: center;
      @media (min-width: ${breakpoints.desktop}) {
        font-weight: 700;
        font-size: 1rem;
      }
    }
    .name {
      font-size: 1em;
      font-weight: 600;
      text-align: right;
      @media (min-width: ${breakpoints.tablet}) {
        margin-left: 3rem;
      }
      @media (min-width: ${breakpoints.tablet}) {
        margin-left: 0rem;
      }
    }
    @media (min-width: ${breakpoints.tablet}) {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      .name {
        font-size: 1em;
        font-weight: 600;
        text-align: right;
      }
    }
  }
  #gridButton {
    background: ${colors.primary};
    text-align: center;
    letter-spacing: 1.7px;
    width: 16.5rem;
    margin: 2rem auto;
  }
`;